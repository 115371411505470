import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/customerCargillAvailableCapacity', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.customer.options = ctx.customerOptions
  fieldsById.receivingUsedCapacityUnity.options =
    ctx.receivingUsedCapacityUnityOptions
  fieldsById.receivingAvailableCapacityUnity.options =
    ctx.receivingAvailableCapacityUnityOptions
}

export default service
