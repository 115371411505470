<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/customerCargillService'
import customerCargillReceivingCapacityService from '../api/customerCargillReceivingCapacityService'
import customerCargillAvailableCapacityService from '../api/customerCargillAvailableCapacityService'
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function,
      details: {}
    }
  },
  methods: {
    async getCustomerCargillReceivingCapacityMeta() {
      const customerCargillReceivingCapacityMeta =
        await customerCargillReceivingCapacityService.getMetaWithValidation()
      customerCargillReceivingCapacityMeta.name =
        'customerCargillReceivingCapacity'
      customerCargillReceivingCapacityMeta.masterName = 'customerId'
      customerCargillReceivingCapacityMeta.fields =
        customerCargillReceivingCapacityMeta.fields.filter(
          (field) => field.id != 'customer'
        )
      customerCargillReceivingCapacityMeta.detailsObject = {
        service: customerCargillReceivingCapacityService
      }

      return customerCargillReceivingCapacityMeta
    },
    async getCustomerCargillAvailableCapacityMeta() {
      const customerCargillAvailableCapacityMeta =
        await customerCargillAvailableCapacityService.getMetaWithValidation()
      customerCargillAvailableCapacityMeta.name =
        'customerCargillAvailableCapacity'
      customerCargillAvailableCapacityMeta.masterName = 'customerId'
      customerCargillAvailableCapacityMeta.fields =
        customerCargillAvailableCapacityMeta.fields.filter(
          (field) => field.id != 'customer'
        )
      customerCargillAvailableCapacityMeta.detailsObject = {
        service: customerCargillAvailableCapacityService
      }

      return customerCargillAvailableCapacityMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getCustomerCargillReceivingCapacityMeta(),
        this.getCustomerCargillAvailableCapacityMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails

      let marketTypeField = meta.fields.find(
        (field) => field.id == 'marketType'
      )
      if (marketTypeField) {
        marketTypeField.options = marketTypeField?.options.filter(
          (option) => option != 'bt'
        )
      }

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        helpers.createLocalityValidations(validationSchema, translate)
        return yup.object().shape(validationSchema, ['latitude', 'longitude'])
      }

      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>
