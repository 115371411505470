import { 
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/customerCargillReceivingCapacity', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.customer.options = ctx.customerOptions
  fieldsById.receivingCapacityUnit.options = ctx.receivingCapacityUnitOptions
}

export default service
